// extracted by mini-css-extract-plugin
export var bets = "KeyFeatures-module--bets--78c2e";
export var card = "KeyFeatures-module--card--cead9";
export var cardHead = "KeyFeatures-module--cardHead--9f52b";
export var colPad = "KeyFeatures-module--colPad--2edd5";
export var description = "KeyFeatures-module--description--26eb0";
export var easyfillImg = "KeyFeatures-module--easyfillImg--8f4cd";
export var heading = "KeyFeatures-module--heading--e465e";
export var laptopContainer = "KeyFeatures-module--laptopContainer--15e32";
export var mainDes = "KeyFeatures-module--mainDes--f2eb9";
export var ocrBets = "KeyFeatures-module--ocrBets--55f11";
export var ocrCard = "KeyFeatures-module--ocrCard--5e0c3";
export var ocrCardHead = "KeyFeatures-module--ocrCardHead--184e7";
export var ocrlaptopContainer = "KeyFeatures-module--ocrlaptopContainer--c454b";
export var ocrmainDec = "KeyFeatures-module--ocrmainDec--5c509";